::-webkit-scrollbar {
    width: 0px;
    /* Remove scrollbar space for Webkit browsers */
    background: transparent;
    /* Optional: make scrollbar invisible */
}

* {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}
