.image-gallery {
    width: 100%;
    height: 60vh;
}

.image-gallery-slide img {
    width: 100%;
    height: 60vh;
    overflow: hidden;
    object-position: center center;
}
